<template lang="pug">
  v-card
    v-toolbar(
      dense
      dark
      color="purple"
      flat
    )
      span List
      v-spacer
      v-icon(
        v-if="restrictedAccess(6)"
        color="white"
        @click="exportIncubatorRackEggs"
        :loading="exportIncubatorRackEggGetting"
      ) mdi-file
    v-container
      v-data-table(
        :loading="incubatorIndexGetting"
        dense
        :items="incubatorIndexList"
        show-expand
        single-expand
        :headers="headers"
        :page.sync="page"
        :items-per-page.sync="itemsPerPage"
        :server-items-length="incubatorIndexGetCount"
      )
        template(v-slot:top)
          v-row
            v-col(lg="3" md="3" cols="12") 
              v-text-field(
                label="search"
                v-model="search"
                append-icon="mdi-magnify"
              )
        template(
          v-slot:item.data-table-expand="{ item, expand, isExpanded }"
        )
          v-icon(
            v-if="restrictedAccess(7)"
            color="yellow darken-3"
            @click="$emit('update:edit', item)"
          ).inline mdi-pencil
          //- base-tooltip(
          //-   color="primary"
          //-   text
          //-   tooltip="Add Rack"
          //-   dense
          //-   small
          //-   bottom
          //-   @click="addRack(item.id)"
          //-   :loading="incubatorAddRackPosting"
          //- )
          v-progress-circular(
            v-if="incubatorAddRackPosting"
            indeterminate
            color="primary"
            :size="24"
          )
          v-tooltip(bottom color="primary" v-else)
            template(v-slot:activator="{ on, attrs }")
              v-icon(
                v-if="restrictedAccess(7)"
                v-on="on"
                v-bind="attrs"
                color="primary"
                @click="addRack(item.id)"
              ).inline mdi-alpha-r-circle
            span Add Rack
          v-icon(
            @click="expand(!isExpanded)"
            :class="!isExpanded || 'rotate--180'"
          ) mdi-chevron-down
        template(v-slot:expanded-item="{ item }")
          td(colspan="3")
            v-row(justify="center")
              v-col(cols="12" v-if="$vuetify.breakpoint.width > 800")
                v-simple-table(dense)
                  thead
                    tr
                      th Rack No.
                      th Qty
                      th Good
                      th Bad
                      th Infertile
                      th Dead Embryo
                      th Current Age
                  tbody
                    tr(v-for="(rack, i) in item.racks" :key="i")
                      td 
                        | {{ rack.code }}
                        v-icon(
                          color="green"
                          v-if="rack.current && rack.current.quantity"
                          @click="$emit('transfer', rack)"
                        ) mdi-transfer
                        v-icon(
                          color="grey"
                          v-else
                        ) mdi-transfer
                        v-icon(
                          v-if="rack.current && rack.current.harvested && rack.current.quantity"
                          color="red"
                          @click="emptyRack(rack)"
                        ) {{ rack.current.quantity }}
                      td {{ objectNull(rack.current).quantity }}
                      td {{ objectNull(rack.current).good }}
                      td {{ objectNull(rack.current).bad }}
                      td {{ objectNull(rack.current).infertile }}
                      td {{ objectNull(rack.current).dead_embryo }}
                      td {{ objectNull(rack.current).current_age }}
              v-col(cols="12" v-else)
                v-simple-table(dense)
                  tbody
                    tr(v-for="rack in item.racks" :key="rack.id")
                      td.pa-0
                        v-list
                          v-list-item
                            v-list-item-content.font-weight-bold Config
                            v-list-item-action
                              v-icon(
                                v-if="rack.current"
                                color="green"
                                @click="$emit('transfer', rack)"
                              ) mdi-transfer
                          v-list-item
                            v-list-item-content.font-weight-bold Rack No.
                            v-list-item-content.text-right.font-weight-bold.primary--text {{ rack.code }}
                          v-list-item
                            v-list-item-content.font-weight-bold Qty
                            v-list-item-content.text-right {{ objectNull(rack.current).quantity }}
                          v-list-item
                            v-list-item-content.font-weight-bold Good
                            v-list-item-content.text-right {{ objectNull(rack.current).good }}
                          v-list-item
                            v-list-item-content.font-weight-bold Bad
                            v-list-item-content.text-right {{ objectNull(rack.current).bad }}
                          v-list-item
                            v-list-item-content.font-weight-bold Infer
                            v-list-item-content.text-right {{ objectNull(rack.current).infertile }}
                          v-list-item
                            v-list-item-content.font-weight-bold Dead
                            v-list-item-content.text-right {{ objectNull(rack.current).dead_embryo }}
                          v-list-item
                            v-list-item-content.font-weight-bold Age
                            v-list-item-content.text-right {{ objectNull(rack.current).current_age }}

</template>
<style lang="sass" scoped>
  .text-right.v-list-item__content
    justify-content: end
    text-align: right
</style>
<script>
import incubatorRepository from '@/repositories/incubator.repository'
import rackEggRepository from '@/repositories/rack-egg.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [incubatorIndexVars, incubatorIndexVarNames] = requestVars({ identifier: 'incubator-index', pagination: true })
const [incubatorAddRackVars, incubatorAddRackVarNames] = requestVars({ identifier: 'incubator-add-rack', request: 'post', hasData: false })
const [exportIncubatorRackEggVars, exportIncubatorRackEggVarNames] = requestVars({ identifier: 'export-incubator-rack-egg', hasData: false })
const [emptyRackEggVars, emptyRackEggVarNames] = requestVars({ identifier: 'empty-rack-egg', method: 'post' })

const incubatorIndexHandler = new VueRequestHandler(null, incubatorIndexVarNames, {}, true)
const incubatorAddRackHandler = new VueRequestHandler(null, incubatorAddRackVarNames)
const exportIncubatorRackEggHandler = new VueRequestHandler(null, exportIncubatorRackEggVarNames)
const emptyRackEggHandler = new VueRequestHandler(null, emptyRackEggVarNames)

const tableVars = () => ({
  headers: [
    { text: 'Code', value: 'code' },
    { text: 'Location', value: 'location' },
    { text: 'Config', value: 'data-table-expand', sortable: false, width: '150px' },
  ],
  page: 1,
  itemsPerPage: 5,
  search: null,
})

export default {
  name: 'TableIncubator',
  props: {
    edit: Object,
  },
  data () {
    return {
      ...tableVars(),
      ...incubatorIndexVars,
      ...incubatorAddRackVars,
      ...exportIncubatorRackEggVars,
      ...emptyRackEggVars,
    }
  },
  computed: {
    incubatorIndexParams () {
      return {
        page: this.page,
        limit: this.itemsPerPage,
        search: this.search,
      }
    },
  },
  created () {
    this.getIncubators()
    this.initWebsocket()
  },
  watch: {
    incubatorIndexParams () {
      this.getIncubators()
    },
    search () {
      this.page = 1
    },
  },
  methods: {
    restrictedAccess (level) {
      return this.$store.state.auth.accessLevel >= level
    },
    initWebsocket () {
      const { echo } = this.$store.state.websocket
      const dbStoreEvent = ({ data, model }) => {
        if (model === 'Incubator') {
          this.incubatorIndexList.push(data)  
          this.incubatorIndexGetCount++
          return
        }
        if (model === 'IncubatorRack') {
          const { incubatorIndexList } = this
          const pushRacks = (item, data) => {
            if (!item.racks) {
              item.racks = [data]
              return
            }
            item.racks.push(data)
          }
          this.incubatorIndexList = incubatorIndexList.map(item => {
            if (item.id === data.incubator_id) pushRacks(item, data)
            return Object.assign({ ...item })
          })
        }
      } 
      const dbUpdateEvent = ({ data, model }) => {
        if (model === 'Incubator') {
          const { incubatorIndexList } = this
          this.incubatorIndexList = incubatorIndexList.map(item => {
            if (item.id === data.id) return Object.assign({ ...data })
            return Object.assign({ ...item })
          })
        }
        if (model === 'RackEgg') {
          const racks = [].concat(...this.incubatorIndexList.map(item => item.racks))
          const found = racks.findIndex(item => (item.current || {}).rack_egg_id === data.id)
          if (found > -1) this.getIncubators()
          return
        }
        if (model === 'IncubatorRackEgg') {
          const racks = [].concat(...this.incubatorIndexList.map(item => item.racks))
          const found = racks.findIndex(item => (item.current || {}).rack_egg_id === data.rack_egg_id)
          if (found === -1) return
          const rack = racks[found]
          rack.current = { ...rack.current, ...data }
          const mapRacks = itemRack => {
            if (itemRack.id === rack.id) itemRack = rack
            return Object.assign({ ...itemRack })
          }
          const { incubatorIndexList } = this
          this.incubatorIndexList = incubatorIndexList.map(item => {
            if (rack.incubator_id === item.id) {
              const racks = item.racks.map(mapRacks)
              return Object.assign({ ...item, racks })
            }
            return Object.assign({ ...item })
          })
        }
      } 
      echo.private('database.event')
        .listen('DBStoreEvent', dbStoreEvent)
        .listen('DBUpdateEvent', dbUpdateEvent)
    },
    getIncubators () {
      const handler = incubatorIndexHandler
      const repository = incubatorRepository.index
      handler.setVue(this)
      handler.execute(repository, [this.incubatorIndexParams])
    },
    async addRack (id) {
      const confirm = await this.$confirm('are you sure you want to add rack to this incubator?')
      if (!confirm) return
      const handler = incubatorAddRackHandler
      const repository = incubatorRepository.addRack
      handler.setVue(this)
      handler.execute(repository, [id])
    },
    exportIncubatorRackEggs () {
      const handler = exportIncubatorRackEggHandler
      const repository = incubatorRepository.exportIncubatorRackEggs
      handler.setVue(this)
      handler.execute(repository, [])
        .then(({ data }) => { Window.downloadExcel(data) })
        .catch(e => { alert(e) })
    },
    objectNull (attr) {
      return attr || {}
    },
    async emptyRack ({ code, current }) {
      const confirm = await this.$confirm(`are you sure you want to empty ${code}`)
      if (!confirm) return
      const rackEggId = current.rack_egg_id
      const handler = emptyRackEggHandler
      const repository = rackEggRepository.empty
      handler.setVue(this)
      handler.execute(repository, [rackEggId])
    },
  },
}
</script>